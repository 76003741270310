@font-face {
  font-family: 'EnnVisionR';
  src: url('./assets/fonts/EnnVisions-Regular.otf') format("opentype");
}
@font-face {
  font-family: 'EnnVisionM';
  src: url('./assets/fonts/EnnVisions-Medium.otf') format("opentype");
}
@font-face {
  font-family: 'EnnVisionB';
  src: url('./assets/fonts/EnnVisions-Bold.otf') format("opentype");
}
@font-face {
  font-family: 'EnnVisionL';
  src: url('./assets/fonts/EnnVisions-Light.otf') format("opentype");
}
body{
  font-family: 'EnnVisionR';
  background-color: #000000;
  color: #fff;

}
h1,h2,h3,h4,h5,h6{
  color: #fff;
}
.page{
  min-height: 100vh;
  width: 100%;
  padding-top: 4rem;
}
.ant-input-affix-wrapper{
  border-radius: 5px;
}
.ant-input{
  border: 0.5px solid#C6C6C8;
  border-radius: 5px;
  height: 40px;
  font-family: 'EnnVisionM';


}
.ant-input::placeholder{
  font-size: 15px;
  color: #909090;
  font-family: 'EnnVisionM';
  
}
.ant-input-prefix {
  margin-right: 7px;
}
.green-bg{
background-color: #38B648 !important;

}
.submit-btn{
background-color: #C7112B ;
color: white;
width: 100%;
height: 45px;
border: 0;
font-size: 19px;
border-radius: 5px;
/* padding: 10px 0px; */
}

.header-title{
  font-size: 17px;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}
.camera{
  position: absolute;
  z-index: 1;
  top: 70px;
  right: 1px;
}
.menu-sec ul {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}
.menu-sec ul li{  
  padding: 12px 15px;
    background: #575555;
    margin-bottom: 1.2rem;
}
.menu-sec ul li span{  
 font-size: 17px;
 margin-left: 1rem;
}
.location-card{
  border-radius: 5px;
  padding: 8px;
  border :.5px solid#C6C6C8;
}
.location-card .title{
  font-size: 18px;
  font-family: 'EnnvisionM';
}
.txt-light{
  font-family: 'EnnvisionL';
}
.ply-btn{
  position: absolute;
  top: 30%;
    left: 30%;
  z-index: 1;
  
}
.loc-list{
  list-style: none;
  padding: 0 5px;
}
.loc-title{
color: #000000;
font-family: 'EnnvisionB';
}
.loc-sub-title{
color: #000000;
font-family: 'EnnvisionM';

}
.search-box{
  border-radius: 5px;
  padding: 5px;
}
.chat-list{
  padding: 0;
  list-style: none;
}
.chat-list li{
  padding: 15px 0;
  border-bottom: 1px solid gray;
}
.fm{
  font-family: 'EnnvisionM';
}
.fl{
  font-family: 'EnnvisionL';
}
.message-input-box{
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  /* position: absolute; */
  /* bottom: 0; */
}
.msg{
  background-color:#363636;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
}
.time{
  font-family: 'EnnvisionL';
  font-size: 12px;
  color: #B8B8B8;

}
.img-box{
  width: 160px;
  padding: 4px;
  border-radius: 4px;
  background-color: #2C7BE5;
}
.own-msg{
  background-color: #2C7BE5;
}
.msg-input:focus{
  border-right: 0;
  border: none;
  box-shadow: none;
}
.msg-input{
  
  border: none;
  box-shadow: none;
}

.chat-cont{

  height: calc(100vh - 67px);
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
